table.data-table {
  width: 100% !important;
  border: 0.1rem solid #000000 !important;

  thead {
    background-color: #fed309 !important;
  }

  tbody {
    background-color: #fff1b3 !important;
  }

  th, td {
    border: 0.1rem solid #000000 !important;
    padding: 0.25rem !important;
    cursor: default !important;
  }

  th.item, td.item {
    text-align: center !important;
  }
}