
.payment-form {

  .credit-card-input {
    background: #FFFF !important;
    padding: 0.5rem;

  }


.payment-icon {
  width: 4rem;
  height: 4rem;
}

ul {
  list-style-type: none;

  li.payment-method-selection-item {
    padding: 0.25rem;
    margin-right: 2rem;
    margin-bottom: 1rem;
    cursor: pointer;

    img,
    p {
      display: inline-block;
    }

    img {
      margin-right: 1rem;
    }

    p {
      font-size: 1.25rem;
    }
  }

  li.payment-method-selection-item:hover {
    background-color: ivory;
  }
}

div.action-controls {
  padding: 0.5rem;
  justify-content: flex-start !important;
}

.credit-card-input {
  height: 2.625rem;
  width: 100%;
  background: #f7f6f5;

  color: #515459;
  font-size: 1rem;
  font-weight: 500;
  line-height: 2.75rem;
  text-align: center;
  border: 0.0625rem solid #d8e0e5;
}

form#credit-card-payment-form {
  padding: 0.5rem;
}

div.alot-credit-card-input-field {
  margin-bottom: 1rem;
}

.payment-row {
  .not-active {
    cursor: pointer;
  }
  .paypal-button {
    margin-top: 1.5rem;
  }
  .alot-primary-button {
    width: 16rem;
  }
  .invalid-feedback {
    padding-left: 0;
  }
  .type {
    height: 1.625rem;
    width: 2.5rem;
    border-radius: 0.1875rem;
    border: 0.0625rem solid #f9f4ed;
    margin-left: 2rem;
  }

  .form-wrapper {
    padding: 0.9375rem;
        border: 0.0625rem solid #f9f4ed;
        border-radius: 0.3125rem;
        margin: 1rem;

    
    .form-check {
      
        display: flex;
        align-items: center;   
        width: 100%;
        height: 20%;
    
    
        input {
          margin: 0rem;
          
        }
        span {
          margin-left: 2rem;
          width: 100%;
    
        }
     
     
     
     
      }
  }


.cards {
  display: flex;
width:100%;
margin-right: 2rem;

  .cards-container {
    margin-left: auto;
    order: 2;
    img {
    width: 2rem;
    height: 2rem;
    margin-left: auto;

  }
}
}

}
}