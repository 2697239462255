.MuiPickersBasePicker-container .MuiSvgIcon-root {
  fill: #d8e0e5 !important;
}

.MuiPickersToolbar-toolbar,
.MuiPickersDay-daySelected,
.MuiTabs-flexContainer,
.MuiPickersClock-pin,
.MuiPickersClockPointer-pointer,
.MuiPickersClockNumber-clockNumberSelected {
  background-color: #7194aa !important;
}

.MuiButton-textPrimary {
  color: #7194aa !important;
}

.MuiPickersToolbarText-toolbarTxt {
  color: #d8e0e5 !important;
}

[class^="MuiTypography-"] {
  font-family: "Raleway", sans-serif !important;
  -webkit-font-feature-settings: "lnum" !important;
  -moz-font-feature-settings: "lnum" !important;
  font-feature-settings: "lnum" !important;
}
