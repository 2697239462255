div.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.error-select {
  .multiselect-container {
    border: 1px solid #dc3545 !important;
    border-radius: 3px;
  }
}

div.search-wrapper {
  padding: 0px;
  background-color: transparent;
  min-height: 0px;
}