html,
body,
#root,
.App,
.content-page,
.container-fluid {
  height: 100%;
  font-family: "Raleway", sans-serif;
  -webkit-font-feature-settings: "lnum";
  -moz-font-feature-settings: "lnum";
  font-feature-settings: "lnum";
  a {
    text-decoration: none;
    color: #585b5f;
  }

  .dropdown .btn {
    padding: 0;
  }

  .dropdown .btn-primary,
  .dropdown .btn-primary:not(:disabled):not(.disabled):active,
  .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    height: 3.125rem;
    width: 3.125rem;
    background: #fed309;
    color: #515459;
    font-size: 1.25rem;
    font-weight: bold;
    line-height: 2.75rem;
    text-align: center;
    border-radius: 0;
    border: 1px solid #fed309;
    outline: none;
    box-shadow: none !important;
    text-transform: uppercase;
    border-radius: 6.25rem;
  }
  .mobile-menu {
    height: 3.125rem;
    width: 3.125rem;
    color: #515459;
    font-size: 1.25rem;
    font-weight: bold;
    line-height: 2.75rem;
    text-align: center;
    border-radius: 0;
    border: none;
    outline: none;
    box-shadow: none !important;
    text-transform: uppercase;
  }
  .dropdown-toggle::after {
    display: none;
    border: none;
    outline: none;
    box-shadow: none !important;
  }
  .show button {
    color: #ffffff !important;
  }

  button {
    outline: none;
  }

  .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: none !important;
  }

  a:hover {
    text-decoration: none;
    cursor: pointer;
    color: #585b5f;
  }
}
.content-page {
  background: #eef1f3;

  main {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
    background: #eef1f3;

    .tab-menus {
      .menu-item {
        padding-right: 1.25rem;
      }
    }
    .table-body {
      background: #d8e0e5;
      border-top-left-radius: 2rem;
      border-top-right-radius: 2rem;
      border-bottom-left-radius: 1.5rem;
      border-bottom-right-radius: 1.5rem;
      padding: 1.25rem;
      padding-left: 0rem;
      padding-right: 0rem;
      padding-bottom: 0rem;
      padding-top: 0rem;
      box-shadow: 0 0 0.5rem 0.02rem rgba(0, 0, 0, 0.16);

      table {
        background: transparent;

        td {
          vertical-align: middle;
        }

        thead {
          background: transparent;

          tr:first-child {
            th {
              color: #515459;
              border: none;
              background: #d8e0e5;
            }

            th:first-child {
              border-top-left-radius: 1.5rem;
              padding-left: 2rem;
            }

            th:last-child {
              border-top-right-radius: 1.5rem;
            }
          }
        }

        thead tr:first-child {
          border: none;
          background: transparent;
        }

        tbody td:first-child {
          padding-left: 2rem;
        }

        tbody {
          tr:last-child {
            background: transparent;

            td:first-child {
              border-bottom-left-radius: 0.75rem;
              border: none;
            }

            td:last-child {
              border-bottom-right-radius: 0.75rem;
              border: none;
            }
          }

          tr:nth-last-child(1):nth-child(odd) {
            td {
              background: #ffffff;
            }
          }

          tr:nth-last-child(1):nth-child(even) {
            td {
              background: #f8f9fb;
            }
          }
        }

        tr:nth-child(odd) {
          background: #ffffff;
        }

        tr:nth-child(even) {
          background: #f8f9fb;
        }
      }
    }
  }
  .container-fluid {
    .row:first-child {
      height: 100%;
    }
  }
}
.search-wrapper {
  background: #ffffff;
  border: none !important;
  .chip {
    border-radius: 2rem;
    background: #7296ab;
    font-size: 0.9rem;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    padding-top: 3px !important;
    padding-bottom: 3px !important;

    .icon_cancel {
      margin-left: 0.5rem;
      width: 1rem;
      height: 1rem;
      font-size: 0rem;
      font-weight: 100;
      background-image: url("assets/images/close.svg");
    }
  }
}
.optionContainer {
  li:hover,
  li:focus {
    background: #7296ab !important;
  }
}

.MuiRadio-colorSecondary {
  color: #3f51b5 !important;
  .Mui-checked {
    color: #3f51b5 !important;
  }
}

.form-control.is-invalid {
  border-color: #ed6a5a;
}

div.invalid-feedback {
  color: #ed6a5a;
  padding-left: 1.5875rem;
  padding-right: 1.5875rem;
}

.action-buttons {
  display: flex;

  div {
    button {
      margin-right: 1rem;
      margin-bottom: 1rem;
    }

    button:last-child {
      margin-right: 0rem;
    }
  }
}

@media (max-width: 768px) {
  .action-buttons {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
}

@media (min-width: 768px) {
  .action-buttons {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
    margin-right: 0.9375rem;
  }
}

img.action {
  width: 1.75rem;
  height: 1.75rem;
}

.MuiSvgIcon-root {
  fill: #7a99ac !important;
}

.alert-primary {
  background-color: #ffffff;
  border: 0.125rem solid #7194aa;
  color: #7194aa;
  border-radius: 0.5rem;
}

div.driverPaymentSettingsForm,
div.clientPaymentSettingsForm {
  img {
    height: 3rem;
  }
}

img.spinner-image {
  height: 3rem;
}
