.alot-primary-input {
  height: 2.625rem;
  width: 100%;
  background: #f7f6f5;

  color: #515459;
  font-size: 1rem;
  font-weight: 500;
  line-height: 2.75rem;
  text-align: center;
  border: 0.0625rem solid #d8e0e5;
}

.alot-primary-input.login-field {
  height: 2.625rem;
  width: 100%;
  background: #ffffff;

  color: #515459;
  font-size: 1rem;
  font-weight: 500;
  line-height: 2.75rem;
  text-align: center;
  border: 0.0625rem solid #7194aa;
  border-top: 0.0625rem solid transparent;
  border-left: 0.0625rem solid transparent;
  border-right: 0.0625rem solid transparent;
}

.alot-primary-input.login-field:focus {
  outline-width: 0;
  outline: none;
  border: 0.0625rem solid #fed309;
  border-top: 0.0625rem solid transparent;
  border-left: 0.0625rem solid transparent;
  border-right: 0.0625rem solid transparent;
}
