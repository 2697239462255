.header {
  border-bottom: solid #fed309;
  padding: 2.5rem 3rem 2.5rem 3rem;
  .header-row {
    .card {
      width: 25%;
    }
    display: flex;
    justify-content: space-between;
    .logo {
      width: 9.75rem;
    }
    .menus {
      display: flex;
      @media (max-width: 768px) {
        .mobile-menu {
          margin-right: 10px;

          .menu_toggle {
            width: 100%;
            height: 100%;
            padding: 4px 0;

            cursor: pointer;
            transition: 0.3s all;
            -webkit-transition: 0.3s all;
            position: relative;
            top: 4px;
          }

          .bar {
            width: 30px;
            height: 2px;
            margin: 7px auto;
            background: #515459;
            border-radius: 2px;
            -webkit-transition: all 0.3s ease-in-out;
            -moz-transition: all 0.3s ease-in-out;
            -ms-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
            position: relative;
            border-radius: 10px;
          }

          &.active-toggle {
            background: #fed309;
          }
          &.active-toggle .bar2 {
            opacity: 0;
          }
          &.active-toggle .bar1 {
            width: 24px;
            -ms-transform: rotate(45deg);
            -ms-transform-origin: 0%;
            -webkit-transform: rotate(45deg);
            -webkit-transform-origin: 0%;
            transform: rotate(45deg);
            transform-origin: 0%;
            width: 31px;
            top: -2px;
            left: 5px;
          }
          &.active-toggle .bar3 {
            -ms-transform: rotate(-45deg);
            -ms-transform-origin: 0%;
            -webkit-transform: rotate(-45deg);
            -webkit-transform-origin: 0%;
            transform: rotate(-45deg);
            transform-origin: 0%;
            width: 31px;
            top: 2px;
            left: 5px;
          }
        }
      }
      @media (min-width: 768px) {
        .mobile-menu {
          display: none;
        }
      }
    }
  }
}
