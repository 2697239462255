.login-page {
  label {
    margin-bottom: 0;
  }

  .login-logo {
    width: 17.3125rem;
    margin: 0 auto;
    margin-top: 6rem;
    margin-bottom: 6rem;

    .logo {
      fill: #ff0000;
    }
  }

  .tabs-body {
    border: none;
    input {
      text-align: center;
    }

    .table-body {
      border: none;
      box-shadow: none;
      padding: 0px;
      margin: 0px;
    }
  }

  main {
    width: 40%;
    min-width: 18.75rem;
    margin: 0 auto;
    .card-body {
      width: 90%;
      margin: 0 auto;
      label {
        color: #515459;
        margin: 10px;
        font-size: 16px;
        font-weight: bold;
        line-height: 44px;
        text-align: center;
      }
      .alot-primary-button {
        margin-top: 40px;
      }
    }
  }
}
