.tabs-container {
  .tab-menus {
    border: 0px solid transparent;
    border-radius: 2rem;
    background: #D8E0E5;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 1.9rem;
    padding: 0.3rem;
  }
  
  .tabs-title {
    color: #515459;
    font-size: 1.0rem;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    
    /* It's a hack, but a good one: https://stackoverflow.com/a/40884639/1027730 */
    transition: text-shadow 0.5s;
  }

  .menu-item {
    border: 0px solid transparent;
    border-radius: 2rem;
    cursor: pointer;
    padding: 0.3rem 1.0rem 0.3rem 1.0rem;
    background: transparent;
  }

  .menu-item.active {
    background: #FFFFFF;

    > .tabs-title {
      text-shadow: 0.025rem 0 #515459;
    }
  }

    .tabs-body {
      background: #ffffff;
      border: 1px solid #d8e1e5;
      padding: 20px;
      box-shadow: -0.15rem 0.15rem 0.15rem 0.05rem rgba(81,84,89,0.2);
      border-radius: 1rem;

      .table-body {
        background: #ffffff;

        .card-body {
          background: #ffffff;

          .row {
            margin-bottom: 1.25rem;
          }
        }
      }

      input {
        text-align: left;
      }

      label {
        font-weight: 600;
        color: #55585d;
      }

      .form-group {
        &.row {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
}