.sidebar {
  background: #eef1f3;
  @media (min-width: 768px) {
    padding-top: 2.125rem;
  }

  .nav {
    padding-left: 1.5rem;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 0;
    display: inline-block !important;
  }

  ul {
    width: 100%;

    li {
      color: #585b5f;
      list-style-type: none;
      a {
        padding: 0rem 0.6rem 0rem 0.6rem;
      }
      &.active a {
        background: #fed400;
        color: #232b2d !important;
        padding: 0.2rem 0.6rem 0.2rem 0.6rem;
        font-weight: bold;
        border-radius: 1rem;
      }

      .category {
        padding: 0rem 1.2rem 0rem 0.6rem;
        color: #505358;
        font-weight: bold;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
      }
      .active-list {
        display: block;
      }
      ul {
        display: none;
      }
    }
  }
}

@media (min-width: 768px) {
  .sidebar-title {
    display: none;
  }
}
@media (max-width: 768px) {
  .sidebar {
    display: none !important;
  }
  .opened-menu {
    display: flex !important;
    .sidebar-title {
      text-transform: uppercase;

      color: #232b31;
      font-size: 20px;
      font-weight: bold;
      line-height: 76px;
    }
  }
}
