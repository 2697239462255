table.parking-lot-feature-table {
  background-color: #fed309 !important;
  border: 0.1rem solid #000000 !important;

  th,
  td {
    border: 0.1rem solid #000000 !important;
    padding: 0.25rem !important;
    cursor: default !important;
  }

  th.item,
  td.item {
    text-align: center !important;
  }
}

dl {
  dt,
  dd {
    text-align: center;
  }
}
