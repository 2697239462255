
td {
  text-align: left !important;
  font-weight: normal !important;
}

  span.lot-name {
    background-color: #7194aa;
    padding: 0.25rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-left: 0.25rem;
    margin-top: 0.25rem;
    border: 0.1rem solid #7194aa;
    border-radius: 1.0rem;
    color: #232b31;
    display: inline-block;
  }

  .form-control {
    text-align: left !important;
  }