$base-group-data-color: rgb(254, 211, 9);

div.group-data {
  border: 0.15rem solid $base-group-data-color;
  background-color: $base-group-data-color;
  border-radius: 0.5rem;
  padding: 0.2rem;

  p.group-name {
    font-size: 1.25rem;
    font-weight: bold;
  }
}