.page-title-container {
  padding-bottom: 0.5rem;
}

.page-title {
  text-transform: uppercase;
  color: #515459;
  font-size: 1.5rem;
  font-weight: bold;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  text-align: center;
}

@media (max-width: 768px) {
  .page-title-container {
    margin: 0 auto;
  }
}
