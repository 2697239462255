@media (max-width: 768px) {
  div.table-body {
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }
  div.table-body table.table {
    color: #515459;
  }
  div.table-body table.table td.action-cell {
    width: auto;
    white-space: nowrap;
  }
  div.table-body table.table td.label-cell {
    width: auto;
    text-align: left !important;
    font-weight: bold !important;
    padding-left: 0px;
  }
  div.table-body table.table tr {
    background-color: transparent !important;
  }
  div.table-body table.table td.content-cell {
    width: 100%;
  }
  div.table-body table.table tr.element-invisible {
    visibility: collapse;
  }
  div.first-row tr:first-child td:first-child {
    border-top-left-radius: 0.5rem;
  }
  div.first-row tr:first-child td:last-child {
    border-top-right-radius: 0.5rem;
  }
  div.last-row-inactive tr:first-child td:first-child {
    border-bottom-left-radius: 0.5rem;
  }
  div.last-row-inactive tr:first-child td:last-child {
    border-bottom-right-radius: 0.5rem;
  }
  div.last-row-active tr:last-child td:first-child {
    border-bottom-left-radius: 0.5rem;
  }
  div.last-row-active tr:last-child td:last-child {
    border-bottom-right-radius: 0.5rem;
  }
  div.odd-row td {
    background: #ffffff !important;
  }
  div.even-row td {
    background: #f8f9fb !important;
  }
}

div.mobile-row tr:first-child td.label-cell {
  white-space: nowrap;
}

img.show-hide-action {
  width: 1rem;
  height: 1rem;
}
