.access-table {
table {
  td {
    cursor: pointer;
    border: 0.1rem solid #000000;
    text-align: center;
    color: #000000;
    font-weight: bold;
  }

  td.restricted:hover {
    background-color: rgb(194, 62, 88);
  }

  td.restricted {
    background-color: #ff6438;
  }

  td.allowed {
    background-color: #94dc94;
  }

  td.allowed:hover {
    background-color: rgb(10, 250, 110);
  }
}

table.disabled {
  opacity: 0.5;
  pointer-events: none;
}
}