.alot-action-button {
  border: 0rem solid transparent;
  border-radius: 1.5rem;
  color: #515459;
  font-weight: 500;
  text-align: center;

  height: 2.125rem;
  background: #FED309;
  color: #232B31;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: 10px;
  border-radius: 2rem;
  box-shadow: 0rem 0.2rem 0.5rem rgba(0,0,0,0.16);
}