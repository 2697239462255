.alot-primary-button {
  border: 0rem solid transparent;
  border-radius: 1.5rem;
  text-transform: uppercase;
  background: #fed309;
  color: #232b31;
  font-size: 1rem;
  font-weight: bold;
  line-height: 2rem;
  padding: 0.25rem 1.625rem 0.25rem 1.625rem;
  box-shadow: 0rem 0.2rem 0.5rem rgba(0, 0, 0, 0.16);
  svg {
    margin-right: 1rem;
  }
}
