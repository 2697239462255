.alot-secondary-button {
    color: #515459;
    font-weight: 500;
    text-align: center;

    height: 2.125rem;
    border: .125rem solid #EEF1F3;
    background-color: #F8F9FB;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-left: 10px;
    border-radius: 2rem;

    &.active {
        background: #7194AA;
        color:#FFFFFF;
        padding-left: 1rem;
        padding-right: 1rem;
    }
}