table.table td {
  border: none;
}

.item-row {
  border-bottom: 0.1rem solid #343a40;
}

.grand-total-cell {
  font-weight: bold !important;
  text-align: right !important;
}
