.alot-secondary-input {
  height: 2.625rem;
  width: 100%;
  background: #ffffff;
  color: #515459;
  font-size: 1rem;
  font-weight: 500;
  line-height: 2.75rem;
  text-align: center;
  border: 0.0625rem solid #d8e0e5;
  border-radius: 2rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.alot-secondary-input:focus {
  outline-width: 0;
  outline: none;
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0.0625rem solid #fed309;
}
