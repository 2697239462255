@media (max-width: 768px) {
  .item-button-aligned {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
}

@media (min-width: 768px) {
  .item-button-aligned {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
    margin-right: 0rem;
  }
}
