.invoice-details-page {

  .alot-primary-button {
    margin-right: 1rem;
  }

.invoice-preview-body {
  .main-cell {
    vertical-align: top;
  }
}

}