.table-body {
  th {
    vertical-align: inherit !important;
    border-bottom: none;
  }
  .table {
    .action {
      width: 24px;
      margin-right: 5px;
      cursor: pointer;
    }
  }
}

div.mobile-table,
div.desktop-table {
  width: 100%;
}

@media (min-width: 768px) {
  div.mobile-table {
    display: none !important;
  }
}

@media (max-width: 768px) {
  div.desktop-table {
    display: none !important;
  }
}

td.nesting-level-1 {
  padding-left: 2.5rem !important;
}

td.nesting-level-2 {
  padding-left: 3rem !important;
}

td.nesting-level-3 {
  padding-left: 3.5rem !important;
}
