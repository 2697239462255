td {
  text-align: left !important;
  font-weight: normal !important;
}

td.license-plate-number-cell {
  span.license-plate-number {
    background-color: #7194aa;
    padding: 0.25rem;
    margin-left: 0.25rem;
    border: 0.1rem solid #7194aa;
    border-radius: 0.25rem;
    color: #232b31;
  }
}