div.fields-container {
  border-radius: 0.25rem;
  padding: 0.25rem;
  background-color: #F8F9FB;
  border-radius: 0.25rem;
  border: 0.15rem solid #F8F9FB;

  p.fields-container-label {
    font-size: 1.25rem;
    font-weight: bold;
  }

  div.fields-container-details {
    padding: 0.25rem;
  }
}