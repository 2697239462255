div.option-selected {
  border-radius: 2rem;
  background: #7296ab;
  font-size: 0.9rem;
  margin-top: 0rem;
  margin-bottom: 0px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
  color: #ffffff;
  margin-left: 0.5rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  img.remove-option {
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
    cursor: pointer;
  }
}

div.image-centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

img.action {
  cursor: pointer;
}