$plateCardColor: #e6c018;

div.plate-card {
  // background-color: $plateCardColor;
  margin-bottom: 0.5rem;
  padding: 0.25rem;
  // border: 0.1rem solid $plateCardColor;
  border-radius: 0.25rem;

  span.plate-card-header {
    font-size: 1.25rem;
    font-weight: bold;
  }
}