div.hourly-config-form {
  table {
    thead > tr {
      th {
        text-align: center;
      }

      th:first-child,
      th:nth-child(2) {
        padding-right: 2rem;
      }
    }

    tbody > tr {
      td:first-child,
      td:nth-child(2) {
        padding-right: 2rem;
      }
    }
  }
}
